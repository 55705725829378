import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './NavigationMenu.scss';
import { faLocationArrow, faMagnifyingGlassLocation, faStore } from '@fortawesome/free-solid-svg-icons';
import { useContext } from 'react';
import ThemeContext from '../../context/themeProvider';
import { Link, useLocation } from 'react-router-dom';
import translations from '../../translations';
import { useSelector } from 'react-redux';

function NavigationMenu(props) {
  
  const { theme } = useContext(ThemeContext);

  const location = useLocation();

  const languageCode = useSelector((state) => state.app.geolocation.languageCode);
  const t = translations[languageCode];

  return (
    <div className={`NavigationMenu ${theme}`}>
      <div className="content">
        <Link to="/" className={`option ${location.pathname === '/' ? 'active' : ''}`}>
          <div className="icon">
            <FontAwesomeIcon icon={faMagnifyingGlassLocation} size="2x" />
          </div>

          <span dangerouslySetInnerHTML={{ __html: t['navigation-menu']['option-search'] }}></span>
        </Link>

        <Link to="/nearby" className={`option ${location.pathname === '/nearby' ? 'active' : ''}`}>
          <div className="icon">
            <FontAwesomeIcon icon={faLocationArrow} size="2x" />
          </div>

          <span dangerouslySetInnerHTML={{ __html: t['navigation-menu']['option-location-search'] }}></span>
        </Link>

        <div className="option" style={{ opacity: 0.4, cursor: 'default' }}>
          <div className="icon">
            <FontAwesomeIcon icon={faStore} size="2x" />
          </div>

          <span dangerouslySetInnerHTML={{ __html: t['navigation-menu']['option-place-search'] }}></span>
        </div>
      </div>
    </div>
  );
}

export default NavigationMenu;
