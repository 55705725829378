import { useContext, useEffect, useRef, useState } from 'react';
import './AppMenu.scss';
import { useSelector } from 'react-redux';
import ThemeContext from '../../context/themeProvider';
import { faFire, faLanguage, faMoon, faSun } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import store from '../../store/store';
import appSlice from '../../store/appSlice';
import translations from '../../translations';

function AppMenu(props) {
  const isOpen = useSelector((state) => state.app.appMenuOpen);

  const { theme, setTheme } = useContext(ThemeContext);

  const elementRef = useRef(null);

  const languageCode = useSelector((state) => state.app.geolocation.languageCode);
  const t = translations[languageCode];

  function onWindowClick(e) {
    if (!elementRef.current.contains(e.target) && !document.querySelector('.app-menu-container').contains(e.target)) {
      store.dispatch(appSlice.actions.toggleAppMenu(false));
    }
  }

  useEffect(() => {
    window.addEventListener('click', onWindowClick);

    return () => {
      window.removeEventListener('click', onWindowClick);
    };
  }, []);

  return (
    <div className={`AppMenu ${theme} ${isOpen ? '' : 'hidden'}`} ref={elementRef}>
      <div
        className="theme"
        onClick={() => theme === 'light' ? setTheme('dark') : setTheme('light')}
      >
        {
          theme === 'light'
            ? <button><FontAwesomeIcon icon={faMoon} size="2x" /></button>
            : <button><FontAwesomeIcon icon={faSun} size="2x" /></button>
        }
        <span>{t.theme}</span>
      </div>

      <div
        className="language"
      >
        <FontAwesomeIcon icon={faLanguage} size="2x" />
        <span>{t.language}</span>
      </div>

      <div
        className="btn-enable-red"
        onClick={() => false && setTheme('colored')}
      >
        <FontAwesomeIcon icon={faFire} size="2x" />
        <span>Red</span>
      </div>
    </div>
  );
}

export default AppMenu;
