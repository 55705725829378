import { Fragment, useEffect, useState } from 'react';
import './App.scss';
import Header from './components/header/Header';
import request from './request';
import appSlice from './store/appSlice';
import store from './store/store';
import { useSelector } from 'react-redux';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Search from './components/search/Search';
import ThemeContext from './context/themeProvider';
import Results from './components/results/Results';
import NavigationMenu from './components/navigation-menu/NavigationMenu';
import LocationSearch from './components/location-search/LocationSearch';

function App() {
  const [theme, setTheme] = useState(localStorage.theme || 'light');

  const language = useSelector((state) => state.app.geolocation.languageCode);
  const key = useSelector((state) => state.app.key);

  useEffect(() => {
    navigator.permissions.query({ name: "geolocation" }).then((result) => {
      if (result.state === 'granted') {
        navigator.geolocation.getCurrentPosition((position) => {
          store.dispatch(appSlice.actions.updateGeolocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          }));
        }, () => {

        });
      }
    });

    request.get(`/${key !== null ? `?key=${key}` : ''}`).then((response) => {
      console.log(response.data);

      if (response.data.key) {
        store.dispatch(appSlice.actions.updateKey(response.data.key));
        localStorage.setItem('session-key', response.data.key);
      }

      if (language === null || language === 'unknown') {
        let languageCode = 'en';

        if (response.data.geolocation.country === 'GR') {
          languageCode = 'el';
        }

        store.dispatch(appSlice.actions.updateGeolocation({ languageCode }));
        localStorage.setItem('lang', languageCode);
      }

      if (response.data.geolocation.country) {
        store.dispatch(appSlice.actions.updateGeolocation({ countryCode: response.data.geolocation.country }));
      }
      
      store.dispatch(appSlice.actions.updateCities(response.data.metadata.cities));
      store.dispatch(appSlice.actions.updateCategories(response.data.metadata.categories));
    });
  }, []);

  useEffect(() => {
    localStorage.setItem('theme', theme);
  }, [theme]);
  
  return (
    <div className={`App ${theme}`}>
      <ThemeContext.Provider value={{ theme, setTheme }}>
        <BrowserRouter>
          <Header></Header>

          <main>
            <Routes>
              <Route exact path="/" element={<Search />}></Route>
              <Route path="/results/location/:coordinates" element={<Results />}></Route>
              <Route path="/results/:city/:category" element={<Results />}></Route>
              <Route path="/results/:city/:region/:category" element={<Results />}></Route>
              <Route path="/nearby" element={<LocationSearch />}></Route>
              <Route path="*" element={<Search />}></Route>
            </Routes>
          </main>

          <NavigationMenu></NavigationMenu>
        </BrowserRouter>
      </ThemeContext.Provider>
    </div>
  );
}

export default App;
