import { createSlice } from "@reduxjs/toolkit";

const appSlice = createSlice({
  name: 'app',
  initialState: {
    appMenuOpen: false,
    cities: [],
    categories: [],
    results: [],
    geolocation: {
      latitude: null,
      longitude: null,
      languageCode: localStorage.getItem('lang') || 'unknown',
      countryCode: null,
    },
    key: localStorage.getItem('session-key'),
  },
  reducers: {
    toggleAppMenu: (state, action) => {
      state.appMenuOpen = action.payload;
    },
    updateCities: (state, action) => {
      state.cities = action.payload;
    },
    updateCategories: (state, action) => {
      state.categories = action.payload;
    },
    updateSearchResults: (state, action) => {
      state.results = action.payload;
    },
    updateGeolocation: (state, action) => {
      if (action.payload.latitude) {
        state.geolocation.latitude = action.payload.latitude;
      }

      if (action.payload.longitude) {
        state.geolocation.longitude = action.payload.longitude;
      }
      
      if (action.payload.languageCode) {
        state.geolocation.languageCode = action.payload.languageCode;
      }

      if (action.payload.countryCode) {
        state.geolocation.countryCode = action.payload.countryCode;
      }
    },
    updateKey: (state, action) => {
      state.key = action.payload;
    },
  },
});

export default appSlice;
