import axios from 'axios';

// let baseURL = 'http://localhost/blue-wizard/api/';
let baseURL = 'https://www.bluewizard.app/api/';

if (process.env.NODE_ENV === 'production') {
  baseURL = 'https://www.bluewizard.app/api/';
}

const request = axios.create({
  baseURL: baseURL,
  withCredentials: true,
});

export default request;
