import { useSelector } from 'react-redux';
import './Search.scss';
import Select from '../select/Select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBagShopping, faBasketShopping, faBed, faBellConcierge, faBuildingColumns, faCircleNotch, faDumbbell, faGasPump, faKitMedical, faMugSaucer, faPersonDress, faSearch, faShoePrints, faUserTie, faUtensils } from '@fortawesome/free-solid-svg-icons';
import { useState, useContext, Fragment } from 'react';
import request from '../../request';
import store from '../../store/store';
import appSlice from '../../store/appSlice';
import { useNavigate } from 'react-router-dom';
import ThemeContext from '../../context/themeProvider';
import translations from '../../translations';

function Search(props) {
  const [selectedCategory, setSelectedCategory] = useState(-1);
  const [selectedCity, setSelectedCity] = useState(-1);
  const [selectedRegion, setSelectedRegion] = useState(-1);
  const [fetchingData, setFetchingData] = useState(false);

  const categories = useSelector((state) => state.app.categories);

  const categoryIcons = [
    {
      slug: 'restaurant',
      icon: <FontAwesomeIcon icon={faBellConcierge} size="lg" />,
    },
    {
      slug: 'cafeteria',
      icon: <FontAwesomeIcon icon={faMugSaucer} size="lg" />,
    },
    {
      slug: 'super-market',
      icon: <FontAwesomeIcon icon={faBasketShopping} size="lg" />,
    },
    {
      slug: 'men-clothes',
      icon: <FontAwesomeIcon icon={faUserTie} size="lg" />,
    },
    {
      slug: 'women-clothes',
      icon: <FontAwesomeIcon icon={faPersonDress} size="lg" />,
    },
    {
      slug: 'men-shoes',
      icon: <FontAwesomeIcon icon={faShoePrints} size="lg" />,
    },
    {
      slug: 'women-shoes',
      icon: <FontAwesomeIcon icon={faBagShopping} size="lg" />,
    },
    {
      slug: 'hotel',
      icon: <FontAwesomeIcon icon={faBed} size="lg" />,
    },
    {
      slug: 'gas-station',
      icon: <FontAwesomeIcon icon={faGasPump} size="lg" />,
    },
    {
      slug: 'bank',
      icon: <FontAwesomeIcon icon={faBuildingColumns} size="lg" />,
    },
    {
      slug: 'pharmacy',
      icon: <FontAwesomeIcon icon={faKitMedical} size="lg" />,
    },
    {
      slug: 'gym',
      icon: <FontAwesomeIcon icon={faDumbbell} size="lg" />,
    },
  ];

  const cities = useSelector((state) => state.app.cities);
  const geolocation = useSelector((state) => state.app.geolocation);

  const { theme } = useContext(ThemeContext);

  const navigate = useNavigate();

  const languageCode = useSelector((state) => state.app.geolocation.languageCode);
  const t = translations[languageCode];

  function onCategorySelect(category) {
    setSelectedCategory(category.id);
  }

  function onCitySelect(city) {
    setSelectedCity(city.id);
  }

  function onRegionSelect(region) {
    setSelectedRegion(region.id);
  }

  function search() {
    if (fetchingData || (selectedCategory === -1 || selectedCity === -1)) {
      return false;
    }

    setFetchingData(true);
    store.dispatch(appSlice.actions.updateSearchResults([]));

    request.post(`search?languageCode=${geolocation.languageCode}`, {
      category: selectedCategory,
      city: selectedCity,
      region: selectedRegion,
    }).then((response) => {
      console.log(response.data);
      store.dispatch(appSlice.actions.updateSearchResults(response.data));
      setFetchingData(false);

      if (selectedRegion > -1) {
        navigate(`results/${cities.find((c) => c.id === selectedCity).slug}/${cities.find((c) => c.id === selectedCity).regions.find((r) => r.id === selectedRegion).slug}/${categories.find((c) => c.id === selectedCategory).slug}`);
      } else {
        navigate(`results/${cities.find((c) => c.id === selectedCity).slug}/${categories.find((c) => c.id === selectedCategory).slug}`);
      }
    }).catch((error) => {
      setFetchingData(false);
    });
  }

  return (
    <div className={`Search view ${theme}`}>
      {
        categories.length
          ? <div className="container">
              <Select
                placeholder={t['what-are-you-looking-for']}
                data={categories}
                icons={categoryIcons}
                onChange={onCategorySelect}
              ></Select>

              <Select
                placeholder={t['choose-city']}
                data={cities}
                onChange={onCitySelect}
                disabled={selectedCategory === -1}
              ></Select>

              <Select
                placeholder={t['choose-region']}
                data={(selectedCity > -1 ? cities.find((c) => c.id === selectedCity).regions : [])}
                onChange={onRegionSelect}
                disabled={selectedCity === -1}
              ></Select>

              <button
                className={`btn-search ${fetchingData || (selectedCategory === -1 || selectedCity === -1) ? 'disabled' : ''}`}
                onClick={search}
              >
                {
                  fetchingData
                    ? <Fragment>
                        <FontAwesomeIcon icon={faCircleNotch} spin size="lg"></FontAwesomeIcon>
                        <span>{t['search-by-category-button']}</span>
                      </Fragment>
                    : <Fragment>
                        <FontAwesomeIcon icon={faSearch} size="lg"></FontAwesomeIcon>
                        <span>{t['search-by-category-button']}</span>
                      </Fragment>
                }
              </button>
            </div>
          : ''
      }
    </div>
  )
}

export default Search;
