import { faHatWizard } from '@fortawesome/free-solid-svg-icons';
import './Header.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import ThemeContext from '../../context/themeProvider';
import appMenuIcon from '../../assets/img/app-menu.png';
import { useSelector } from 'react-redux';
import store from '../../store/store';
import appSlice from '../../store/appSlice';
import AppMenu from '../app-menu/AppMenu';
import logo from '../../assets/img/bluewizard-logo-v3-duotone.png';

function Header(props) {
  const { theme } = useContext(ThemeContext);

  const appMenuOpen = useSelector((state) => state.app.appMenuOpen);

  function toggleAppMenu() {
    store.dispatch(appSlice.actions.toggleAppMenu(!appMenuOpen));
  }

  return (
    <div className={`Header ${theme}`}>
      <div className="brand">
        <Link to="/">
          <img src={logo} height="40" />
          <span>Blue Wizard</span>
        </Link>
      </div>

      <div className="right">
        <div className="app-menu-container" style={{ position: 'relative' }}>
          <img src={appMenuIcon} height="30" className="btn-toggle-app-menu" onClick={toggleAppMenu} />
          <AppMenu></AppMenu>
        </div>
      </div>
    </div>
  );
}

export default Header;
