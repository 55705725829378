import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import './Select.scss';
import ThemeContext from '../../context/themeProvider';

function Select(props) {
  const [selectedOption, setSelectedOption] = useState(-1);
  const [isOpen, setIsOpen] = useState(false);

  const { theme } = useContext(ThemeContext);

  const element = useRef(null);

  useEffect(() => {
    if (props.data.length > 0 && selectedOption > -1) {
      if (props.data.find((o) => o.id === selectOption) === undefined) {
        setSelectedOption(-1);
      }
    }
  }, [props.data]);

  const onWindowClick = function(e) {
    if (isOpen && !element.current.contains(e.target)) {
      close();
    }
  };

  useEffect(() => {
    if (element.current) {
      window.addEventListener('click', onWindowClick);
    }

    return () => {
      window.removeEventListener('click', onWindowClick);
    };
  }, [isOpen]);

  function selectOption(option) {
    setSelectedOption(option.id);
    close();
    props.onChange(option);
  }

  function open() {
    if (props.disabled !== true) {
      setIsOpen(true);
    }
  }

  function close() {
    setIsOpen(false);
  }

  function toggleState() {
    if (isOpen) {
      close();
    } else {
      open();
    }
  }

  return (
    <div
      className={`Select ${props.className ? props.className : ''} ${theme} ${isOpen ? 'open' : ''} ${props.disabled === true ? 'disabled' : ''}`}
      ref={element}
    >
      <div
        className="selected-option"
        onClick={toggleState}
      >
        {
          selectedOption > -1
            ? <Fragment>
              {
                props.icons
                  ? props.icons.find((i) => i.slug === props.data.find((o) => o.id === selectedOption).slug).icon
                  : ''
              }

              <span>{props.data.find((o) => o.id === selectedOption)?.name}</span>
            </Fragment>
            : <span style={{marginLeft: '10px'}}>{props.placeholder}</span>
        }
      </div>

      <div className="options">
        {
          props.data.map((option, i) => {
            return (
              <div
                key={i}
                className={`option ${selectedOption === option.id ? 'selected' : ''}`}
                onClick={() => selectOption(option)}
              >
                {
                  props.icons
                    ? props.icons.find((i) => i.slug === option.slug).icon
                    : ''
                }
                
                <span>{option.name}</span>
              </div>
            );
          })
        }
      </div>
    </div>
  );
}

export default Select;
