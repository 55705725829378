import { useSelector } from 'react-redux';
import './Results.scss';
import { useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import ThemeContext from '../../context/themeProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar, faPersonWalking, faSpinner } from '@fortawesome/free-solid-svg-icons';
import request from '../../request';
import moment from 'moment';
import 'moment/locale/el';
import store from '../../store/store';
import appSlice from '../../store/appSlice';

function Results(props) {
  const [results, setResults] = useState([]);
  const [fetchingData, setFetchingData] = useState(false);

  const { theme } = useContext(ThemeContext);

  const categories = useSelector((state) => state.app.categories);
  const cities = useSelector((state) => state.app.cities);

  const searchResults = useSelector((state) => state.app.results);
  const geolocation = useSelector((state) => state.app.geolocation);

  const params = useParams();

  useEffect(() => {
    let places = [];

    searchResults.forEach((place) => {
      places.push({
        ...place,
      });
    });

    setResults(places);
  }, [searchResults]);

  useEffect(() => {
    if (categories.length > 0 && cities.length > 0 && searchResults.length === 0) {
      if (params.category && params.city) {
        const data = {
          category: categories.find((c) => c.slug === params.category).id,
          city: cities.find((c) => c.slug === params.city),
        };

        if (params.region) {
          data.region = cities.find((c) => c.slug === params.city).regions.find((r) => r.slug === params.region).id;
        }

        setFetchingData(true);
        request.post(`search`, data).then((response) => {
          setFetchingData(false);
          store.dispatch(appSlice.actions.updateSearchResults(response.data));
        });
      } else if (params.coordinates) {
        const [latitude, longitude] = params.coordinates.split(',');
        
        setFetchingData(true);
        request.post(`location-search`, {
          lat: latitude,
          long: longitude,
        }).then((response) => {
          setFetchingData(false);
          store.dispatch(appSlice.actions.updateSearchResults(response.data));
        });
      }
    }
  }, [categories, cities]);

  function calculateDistance(lat1, lon1, lat2, lon2) {
    const earthRadius = 6371000; // meters
    const lat1Radians = lat1 * Math.PI / 180;
    const lon1Radians = lon1 * Math.PI / 180;
    const lat2Radians = lat2 * Math.PI / 180;
    const lon2Radians = lon2 * Math.PI / 180;
  
    const deltaLat = lat2Radians - lat1Radians;
    const deltaLon = lon2Radians - lon1Radians;
  
    const a = Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2);
    const b = Math.cos(lat1Radians) * Math.cos(lat2Radians) * Math.sin(deltaLon / 2) * Math.sin(deltaLon / 2);
  
    const c = 2 * Math.atan2(Math.sqrt(a + b), Math.sqrt(1 - a - b));
  
    const distance = earthRadius * c;
  
    return distance;
  }

  return (
    <div className={`Results view ${theme}`}>
      {
        results.length === 0 && fetchingData && <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <FontAwesomeIcon icon={faSpinner} spin size="2x" />
        </div>
      }

      {
        results.map((result, i) => {
          let rating = result.rating;

          if (typeof rating !== 'undefined') {
            rating = parseFloat(rating).toFixed(1).replace('.', ',');
          }

          let address = result.shortFormattedAddress.split(',');

          if (address.length > 1) {
            address = address.slice(0, -1).join(',');
          }

          let todaysHours;
          if (typeof result.regularOpeningHours !== 'undefined') {
            moment.locale(geolocation.languageCode);
            todaysHours = result.regularOpeningHours.weekdayDescriptions[moment().weekday()];
          }

          let query = '';

          if (params.category && params.city) {
            query += categories.find((c) => c.slug === params.category).name;
            query += '+' + cities.find((c) => c.slug === params.city).name;
          }
    
          if (params.region) {
            query += '+' + cities.find((c) => c.slug === params.city).regions.find((r) => r.slug === params.region).name;
          }

          const phoneNumber = result.nationalPhoneNumber || result.internationalPhoneNumber;

          let distance;

          if (geolocation.latitude !== null && geolocation.longitude !== null) {
            distance = calculateDistance(geolocation.latitude, geolocation.longitude, result.location.latitude, result.location.longitude);

            if (distance >= 1000) {
              distance /= 1000;
              distance = parseFloat(distance).toFixed(2).replace('.', ',').replace(',00', '').replace('0', '');
              distance += ' χιλ/μετρα';
            } else {
              distance = Math.round(distance);
              distance += ' μέτρα';
            }
          }

          return (
            <div
              key={i}
              className="business"
            >
              {
                result.image_url
                  ? <div className={`photo-large`} style={{backgroundImage: 'url(' + result.image_url + ')' }}></div>
                  : ''
              }
              
              <div className="details">
                <div className="header">
                  <div className="title">
                    {
                      result.image_url
                        ? <div className="photo hidden" style={{backgroundImage: 'url(' + result.image_url + ')'}}></div>
                        : ''
                    }
                    <div className="name" style={{marginLeft: 0}}>{result.displayName.text}</div>
                  </div>

                  <div className={`rating ${parseInt(rating) > 0 ? '' : 'hidden'}`}>
                    <span className="icon">&#9733;</span>
                    <span>{rating}</span>
                  </div>
                </div>

                <div className="other-details">
                  {
                    result.primaryTypeDisplayName && <div className="type">{result.primaryTypeDisplayName.text}</div>
                  }

                  <a
                    href={`${`https://search.google.com/local/reviews?placeid=${result.id}&lang=${geolocation.languageCode}`}`}
                    target="_blank"
                    className={`review-count ${typeof result.userRatingCount !== 'undefined' ? '' : 'hidden'}`}
                  >
                    {parseInt(result.userRatingCount).toLocaleString().replace(/,/, '.')} Αξιολογήσεις
                  </a>
                </div>

                <div className="contact-details">
                  <a href={result.googleMapsUri} target="_blank" className="address" style={{ flex: 1.5 }}>{address}</a>

                  {
                    typeof phoneNumber !== 'undefined' && <div className={`phone`}>
                      <a href={`tel:${phoneNumber.replace(/ /g, '')}`}>
                        Τηλ:&nbsp;{phoneNumber}
                      </a>
                    </div>
                  }
                </div>

                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '20px'}}>
                  {
                    typeof todaysHours !== 'undefined' && <div className="working-hours">
                      <span className="title">ΣΗΜΕΡΑ</span>

                      <div className="items">
                        <span>
                          {todaysHours}
                        </span>
                      </div>
                    </div>
                  }
                  <div>{result.price_level}</div>
                </div>

                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: '30px'}}>
                  <div className="directions">
                    <span>Οδηγίες</span>

                    <a href={`https://www.google.com/maps/dir/?api=1&destination=${result.location.latitude + '%2C' + result.location.longitude}&destination_place_id=${result.id}&travelmode=walking`} target="_blank" className="text">
                      <FontAwesomeIcon icon={faPersonWalking} size="lg" />
                    </a>
                    
                    <a href={`https://www.google.com/maps/dir/?api=1&destination=${result.location.latitude + '%2C' + result.location.longitude}&destination_place_id=${result.id}&travelmode=driving`} target="_blank" className="text">
                      <FontAwesomeIcon icon={faCar} size="lg" />
                    </a>
                  </div>

                  <span className="distance">{distance}</span>
                </div>
              </div>
            </div>
          );
        })
      }
    </div>
  );
}

export default Results;
