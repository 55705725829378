import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './LocationSearch.scss';
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import { Fragment, useContext, useEffect, useState } from 'react';
import ThemeContext from '../../context/themeProvider';
import request from '../../request';
import store from '../../store/store';
import appSlice from '../../store/appSlice';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function LocationSearch(props) {
  const [message, setMessage] = useState('Μοιραστείτε τη τοποθεσία σας μαζί μας');
  const [geolocationSupported, setGeoLocationSupported] = useState(false);
  const [geolocationStatus, setGeolocationStatus] = useState(null);
  const [fetchingData, setFetchingData] = useState(false);

  const geolocation = useSelector((state) => state.app.geolocation);

  const { theme } = useContext(ThemeContext);

  const navigate = useNavigate();

  useEffect(() => {
    setGeoLocationSupported(navigator.geolocation);
  }, []);

  useEffect(() => {
    if (geolocationSupported) {
      navigator.permissions.query({ name: "geolocation" }).then((result) => {
        setGeolocationStatus(result.state)

        if (result.state === 'granted') {
          setMessage('Βρείτε καταστήματα κοντά σας');
        }
      });
    }
  }, [geolocationSupported]);

  useEffect(() => {
    switch (geolocationStatus) {
      case 'denied':
        setMessage('Ενεργοποιήστε το διαμοιρασμό τοποθεσίας')
        break;
      case 'position-unavailable':
      case 'timeout':
      case 'error':
        setMessage('Δεν καταφέραμε να προσδιορίσουμε τη τοποθεσία σας')
        break;
    }
  }, [geolocationStatus]);

  function onGrantNavigatorPermissionsError(error) {
    switch(error.code) {
      case error.PERMISSION_DENIED:
        setGeolocationStatus('denied');
        break;
      case error.POSITION_UNAVAILABLE:
        setGeolocationStatus('position-unavailable');
        break;
      case error.TIMEOUT:
        setGeolocationStatus('timeout');
        break;
      case error.UNKNOWN_ERROR:
        setGeolocationStatus('error');
        break;
    }
  }

  function grantNavigatorPermissions() {
    navigator.geolocation.getCurrentPosition(search, onGrantNavigatorPermissionsError, {
      highPrecision: true,
    });
  }

  function search(position) {
    if (fetchingData) {
      return false;
    }
    
    setFetchingData(true);
    setMessage('Αναζήτηση...');

    store.dispatch(appSlice.actions.updateGeolocation({
      latitude: position.coords.latitude,
      longitude: position.coords.longitude,
    }));
    
    request.post(`location-search?languageCode=${geolocation.languageCode}`, {
      lat: position.coords.latitude,
      long: position.coords.longitude,
    }).then((response) => {
      console.log(response.data);
      store.dispatch(appSlice.actions.updateSearchResults(response.data));
      setFetchingData(false);
      navigate(`/results/location/${position.coords.latitude},${position.coords.longitude}`);
    });
  }

  return (
    <div className={`LocationSearch ${theme}`}>
      {
        geolocationSupported && <Fragment>
          <span className="tip">{message}</span>

          <div
            className="btn-get-location"
            onClick={grantNavigatorPermissions}
          >
            <FontAwesomeIcon icon={faLocationArrow} size="3x" />
          </div>
        </Fragment>
      }
    </div>
  );
}

export default LocationSearch;
