const translations = {
  en: {
    theme: 'Theme',
    language: 'Language',
    ['what-are-you-looking-for']: 'Looking for?',
    ['choose-city']: 'Choose city',
    ['choose-region']: 'Choose region',
    ['search-by-category-button']: 'Search',
    ['navigation-menu']: {
      ['option-search']: 'Search',
      ['option-location-search']: 'Near you',
      ['option-place-search']: 'Stores',
    },
  },
  el: {
    theme: 'Θέμα',
    language: 'Γλώσσα',
    ['what-are-you-looking-for']: 'Τι ψάχνετε;',
    ['choose-city']: 'Επιλέξτε πόλη',
    ['choose-region']: 'Επιλέξτε περιοχή',
    ['search-by-category-button']: 'Αναζήτηση',
    ['navigation-menu']: {
      ['option-search']: 'Αναζήτηση',
      ['option-location-search']: 'Κοντά σας',
      ['option-place-search']: 'Καταστήματα',
    },
  },
  unknown: {
    ['navigation-menu']: {
      ['option-search']: '&nbsp;',
      ['option-location-search']: '&nbsp;',
      ['option-place-search']: '&nbsp;',
    },
  },
};

export default translations;